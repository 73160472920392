import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { COUNTRIES, STATES, PROVINCES } from "../utils/constants";
import {  validateEmailAgainstDomains } from "../utils/customFunctions";
import { AppContext } from "../utils/context";
import TextField from "../components/TextField";
import SelectField from "../components/SelectField";
import CheckboxField from "../components/CheckboxField";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import CheckoutError from "../components/CheckoutError";

const emailRegex = /^[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/i;

const RecipientForm = props => {
  let length = props.data.recipientCount;
  let nullRecipientError = 0;
  let flagError = 0;
  let emailFlagError = 0;
  let emailFlagUniqueError = 0;
  let emailFlagInvalid = 0;
  Object.keys(props.valid).forEach(function (k) {
    if (k.indexOf("recipientList") >= 0) {
      if (k == "recipientList") {
        emailFlagUniqueError = 1;
      } else if (props.valid[k][0].indexOf("invalid") >= 0) {
        emailFlagInvalid = 1;
      } else {
        flagError = 1;
      }
    }
    if (k.indexOf("recipientLimit") >= 0) {
      nullRecipientError = 1;
    }
  });
  Object.keys(props.data.recipientList).forEach(function (k) {
    if (props.data.recipientList[k]["recipientListEmail"] !== "" && props.data.recipientList[k]["recipientListEmail_confirmation"] !== "") {
      if (props.data.recipientList[k]["recipientListEmail"] !== props.data.recipientList[k]["recipientListEmail_confirmation"]) {
        emailFlagError = 1;
      } else if (!emailRegex.test(props.data.recipientList[k]["recipientListEmail"])) {
        emailFlagInvalid = 1;
      }
    }
  });
  let group = [];
  // Outer loop to create parent
  for (let i = 1; i <= length; i++) {
    group.push(
      <div className="flex flex-wrap lg:px-12 mt-3" key={`group` + i}>
        <label className="labelRecipientDetails">
            {props.localLanguage.general_recipient_num_details.replace('{i}', i)}
        </label>
        <div className="lg:inline-flex w-full">
          <TextField
            index={i}
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="recipientListFirstName"
            name="recipientListFirstName"
            value={props.data.recipientList[i - 1].recipientListFirstName}
            placeholder={props.localLanguage.general_first_name}
            handleChange={props.handleRecipientList(i)}
          />
          <TextField
            index={i}
            container="w-full md:w-1/2 md:pl-3 md:pr-3 md:mb-0"
            id="recipientListLastName"
            name="recipientListLastName"
            value={props.data.recipientList[i - 1].recipientListLastName}
            placeholder={props.localLanguage.general_last_name}
            handleChange={props.handleRecipientList(i)}
          />
        </div>
        <div className="lg:inline-flex w-full">
          <TextField
            index={i}
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="recipientListEmail"
            name="recipientListEmail"
            value={props.data.recipientList[i - 1].recipientListEmail}
            placeholder={props.localLanguage.general_email}
            handleChange={props.handleRecipientList(i)}
          />
          <TextField
            index={i}
            container="w-full md:w-1/2 md:pl-3 mb-6 md:pr-3 md:mb-0"
            id="recipientListEmail_confirmation"
            name="recipientListEmail_confirmation"
            value={props.data.recipientList[i - 1].recipientListEmail_confirmation}
            placeholder={props.localLanguage.general_confirm_email}
            handleChange={props.handleRecipientList(i)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      {nullRecipientError > 0 &&
        <div className="pr-3 mb-6 mt-6 md:mb-0"><i style={{ "color": "red" }}>{props.localLanguage.general_null_recipient_error}</i></div>
      }
      {flagError > 0 &&
        <div className="pr-3 mb-6 mt-6 md:mb-0"><i style={{ "color": "red" }}>{props.localLanguage.general_recipient_info_required}</i></div>
      }
      {emailFlagError > 0 &&
        <div className="pr-3 mb-6 mt-6 md:mb-0"><i style={{ "color": "red" }}>{props.localLanguage.general_email_mismatch}</i></div>
      }
      {flagError < 1 && emailFlagInvalid > 0 &&
        <div className="pr-3 mb-6 mt-6 md:mb-0"><i style={{ "color": "red" }}>{props.localLanguage.general_invalid_email_recipient_error}</i></div>
      }
      {flagError < 1 && emailFlagUniqueError > 0 &&
        <div className="pr-3 mb-6 mt-6 md:mb-0"><i style={{ "color": "red" }}>{props.localLanguage.general_unique_email_recipient_error}</i></div>
      }

      <Dropdown
        container="pr-3 mb-6 mt-6 md:mb-0"
        id="recipientLimit"
        name="recipientLimit"
        value={props.data.recipientCount}
        label={props.localLanguage.general_how_many_recipients}
        optionsCount={props.recipientLimit}
        required
        disabled
      />
      {group}
      <div className="buttonGroupRecipientList md:mt-6">
        {+props.data.recipientCount < +props.recipientLimit &&
          <Button
            id="addRecipientButton"
            rounded
            padded
            color="secondary"
            textColor="primary"
            content={+props.data.recipientCount < 1 ? props.localLanguage.general_add_recipient : "+"}
            onClick={() => props.handleAddRecipient(props.data.recipientCount, props.data.recipientList)}
          />
        }
        {+props.data.recipientCount > 0 &&
          <Button
            id="removeRecipientButton"
            rounded
            padded
            color="secondary"
            textColor="primary"
            content={+props.data.recipientCount < +props.recipientLimit ? "-" : props.localLanguage.general_remove_recipient}
            onClick={() => props.handleRemoveRecipient(props.data.recipientCount, props.data.recipientList)}
          />
        }
      </div>
    </div>
  );
};

class CheckoutPersonalInfo extends Component {
  constructor(props) {
    super(props);
    const { context, recipientLimit, data } = props;
    const isTextSubscribed = data.subscribe.some(option => option.value === "text" && option.checked);

    this.state = {
      recipientLimit: recipientLimit ? recipientLimit : 5,
      validEmail: context.settings.employee_raffle_on_off === '1' ? false : true, //if employee raffle is off then set valid email to true
      validRecipients: true,
      addonCount: Object.keys(context?.event?.addons)?.length ?? 0,
      addonEvents: context?.event?.addons ?? null,
      currentEvent: context?.event,
      showDateOfBirth: isTextSubscribed,
      dateOfBirth: "",
      dateOfBirthError: ""
    }
  }

  componentDidMount() {
    this.props.onDateOfBirthValidated(false);
  }

  handleAddRecipient = (recipientCount, recipientList) => {
    let count = Number(recipientCount) + 1;
    let list = recipientList;
    list[count - 1] = {
      recipientListFirstName: "",
      recipientListLastName: "",
      recipientListEmail: "",
      recipientListEmail_confirmation: ""
    };
    this.props.handleStateRecipientCount(count, list);
  }

  handleRemoveRecipient = (recipientCount, recipientList) => {
    let count = Number(recipientCount) - 1;
    let list = recipientList;
    list.pop();
    this.props.handleStateRecipientCount(count, list);

    if (count < 1) {
      this.setState({ validRecipients: true });
    }

  }

  handleRecipientList = (index) => (e) => {
    const { recipientCount: count, recipientList: list } = this.props.data;
    list[index - 1][e.target.name] = e.target.value;
    this.props.handleStateRecipientCount(count, list);

    const recipientError = list
        .filter(recipient => recipient.recipientListEmail !== "")
        .some(recipient => !this.emailValidation(recipient.recipientListEmail));

    this.setState({ validRecipients: !recipientError });
  }

  emailValidation = (email) => emailRegex.test(email);

  handleEmailChange = (e) => {
    const { handleChange, context } = this.props;

    if (context.settings.employee_raffle_on_off === '1') {
      let { name, value } = e.target;
      let email = this.state;
      let validEmail = this.state;
      email = value;
      validEmail = validateEmailAgainstDomains(email, context.settings.employee_raffle_valid_domains);
      this.setState({ email, validEmail });
    }
    handleChange(e)
  }

  handleSubscribeChange = (e) => {
    const { name, checked, value } = e.target;
    if (value === "text") {
      this.setState({ showDateOfBirth: checked });
    }
    this.props.handleSubscribe(e);
  }

  handleDateOfBirthChange = (e) => {
    const { value } = e.target;
    this.setState({ dateOfBirth: value }, this.validateDateOfBirth);
  }

  validateDateOfBirth = () => {
    const { dateOfBirth } = this.state;
    const { onDateOfBirthValidated, context } = this.props;

    const check = document.getElementById("subscribe-text");
    if (!check.checked) {
      this.setState({ dateOfBirthError: "" }, onDateOfBirthValidated(true));
      return;
    }

    const today = new Date();
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  
    if (!datePattern.test(dateOfBirth)) {
      this.setState({dateOfBirthError: context.localLanguage.invalid_dob_format_text}, onDateOfBirthValidated(false));
      return;
    }
    const birthDate = new Date(dateOfBirth);

    if (isNaN(birthDate.getTime())) {
      this.setState({dateOfBirthError: context.localLanguage.invalid_dob_format_text}, onDateOfBirthValidated(false));
      return;
    }
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    if (check.checked && age < 18) {
      this.setState({ dateOfBirthError: context.localLanguage.age_not_reach_text}, onDateOfBirthValidated(false));
    } else {
      this.setState({ dateOfBirthError: '' }, onDateOfBirthValidated(true)); // Clear validation error in Checkout.js validatePersonal()
    }
  }

  render() {
    const {
      context,
      data,
      handleChange,
      handlePhoneNumber,
      handleGiftTicket,
      handleAgreement,
      valid,
      validatePersonal,
      goToPrevious,
      freeTicket,
      giftingOption,
      invalidEmailError
    } = this.props;
    const {
      recipientLimit,
      validEmail,
      validRecipients,
      addonCount,
      addonEvents,
      currentEvent,
      showDateOfBirth,
      dateOfBirth,
      dateOfBirthError
    } = this.state;
    const { localLanguage, settings } = this.context;
    // Set up age ranges
    const age_range = JSON.parse(context.settings.age_range);
    let ageRanges = [];
    age_range.forEach(agerange => {
      if (agerange.enabled === "1") {
        ageRanges.push({
          key: agerange.index,
          value: agerange.index,
          text: agerange.range
        });
      }
    });

    let agreementErrors = [];
    data.agreement.map((v, k) => {
      if (valid[`agreement.${k}.checked`])
        agreementErrors.push(valid[`agreement.${k}.checked`]);
      else
        agreementErrors.push(null);
      return agreementErrors;
    });

    let countryOptions = COUNTRIES(localLanguage.setLocale);
    let subdivisionOptions = data.country === "CAN" ? PROVINCES(localLanguage.setLocale) : STATES(localLanguage.setLocale);

    // Limit billing addresses feature - for Country
    const limitAddressToNames = context.config.web_limit_address_name;
    if (limitAddressToNames.length > 0) {
      countryOptions = countryOptions.filter(item => limitAddressToNames.includes(item.key.toLowerCase()));
    }
    //// For Subdivision
    const limitAddressToSubNames = context.config.web_limit_address_subname;
    if (limitAddressToSubNames.length > 0) {
      subdivisionOptions = subdivisionOptions.filter(item => limitAddressToSubNames.includes(item.key.toLowerCase()));
    }

    const eventEndedAlert = {
        code: 3,
        message: localLanguage.general_event_end_return_home,
        action: "default"
    }

    const priceChangeAlert = {
        code: 4,
        message: localLanguage.general_event_pricing_change,
        action: "default"
    }

    if (!context.event.is_active || context.event.web_sales_paused) { // alert user that event ended
        return (<CheckoutError
            error={eventEndedAlert}
            event={context.event}
        />);
    }

    let eventAddonsCount = Object.keys(context?.event?.addons)?.length;
    if (context.config.option_allow_addons && addonCount !== eventAddonsCount) { // alert user of addon event changes
        return (<CheckoutError
            error={priceChangeAlert}
            event={context.event}
        />);
    }

    let prevEventAddons = context?.event?.addons ??  null;
    if (context.config.option_allow_addons && prevEventAddons && addonCount) { // alert user of addon dynamic pricing changes
        const addonPricingChange = addonEvents.every((addon, key) => {
            let prevAddonPrices = context?.event?.addons[key]?.prices;
            if (prevAddonPrices) return (JSON.stringify(prevAddonPrices) !== JSON.stringify(addon.prices));
        });
        if (addonPricingChange)
            return (<CheckoutError
                error={priceChangeAlert}
                event={context.event}
            />);
    }

    let prevEvent = context?.event;
    if (prevEvent && currentEvent && JSON.stringify(prevEvent.prices) !== JSON.stringify(currentEvent.prices)) { // alert user of dynamic pricing changes
        return (<CheckoutError
            error={priceChangeAlert}
            event={context.event}
        />);
    }

    let ageVerificationRequiredSms = +context?.settings?.age_verification_required_sms === 1;

    return (
      <form className="w-full md:w-2/3 pr-4 checkoutPersonalInfo">
        <Helmet>
          {context.settings.scripts_personal_info && <script>{context.settings.scripts_personal_info}</script>}
        </Helmet>
        <h2 className="mb-6">{localLanguage.general_personal_info}</h2>
        <div className="flex flex-wrap mb-6">
          <TextField
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="first_name"
            name="first_name"
            value={data.first_name}
            label={localLanguage.general_first_name}
            placeholder={localLanguage.general_first_name}
            required
            handleChange={handleChange}
            hasError={valid["first_name"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="last_name"
            name="last_name"
            value={data.last_name}
            label={localLanguage.general_last_name}
            placeholder={localLanguage.general_last_name}
            required
            handleChange={handleChange}
            hasError={valid["last_name"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>
        <div className="flex flex-wrap mb-6">
          <TextField
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="email"
            name="email"
            value={data.email}
            label={localLanguage.general_email}
            placeholder={localLanguage.general_email}
            required
            handleChange={this.handleEmailChange}
            hasError={valid["email"] || invalidEmailError}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="email_confirmation"
            name="email_confirmation"
            value={data.email_confirmation}
            label={localLanguage.general_confirm_email}
            placeholder={localLanguage.general_confirm_email}
            required
            handleChange={this.handleEmailChange}
            hasError={valid["email_confirmation"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>
        <div className="flex flex-wrap mb-6">
          <TextField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="phone"
            name="phone"
            value={data.phone}
            label={localLanguage.general_phone_primary}
            placeholder={localLanguage.general_phone_primary}
            required
            handleChange={handleChange}
            onBlur={handlePhoneNumber}
            hasError={valid["phone"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="phone_home"
            name="phone_home"
            value={data.phone_home}
            label={localLanguage.general_phone_home}
            placeholder={localLanguage.general_phone_home}
            handleChange={handleChange}
            onBlur={handlePhoneNumber}
            hasError={valid["phone_home"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="phone_work"
            name="phone_work"
            value={data.phone_work}
            label={localLanguage.general_phone_work}
            placeholder={localLanguage.general_phone_work}
            handleChange={handleChange}
            onBlur={handlePhoneNumber}
            hasError={valid["phone_work"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <SelectField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="age_range"
            name="age_range"
            value={data.age_range}
            label={localLanguage.general_age_range}
            options={ageRanges}
            placeholder={localLanguage.general_age_range}
            handleChange={handleChange}
            hasError={valid["age_range"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>
        <div className="flex flex-wrap mb-6">
          <TextField
            container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
            id="address"
            name="address"
            value={data.address}
            label={localLanguage.general_address}
            placeholder={localLanguage.general_address}
            required
            handleChange={handleChange}
            hasError={valid["address"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="suite"
            name="suite"
            value={data.suite}
            label={localLanguage.general_suite_apt}
            placeholder={localLanguage.general_suite_apt}
            handleChange={handleChange}
            hasError={valid["suite"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/4 md:pr-3 mb-6 md:mb-0"
            id="city"
            name="city"
            value={data.city}
            label={localLanguage.general_city}
            placeholder={localLanguage.general_city}
            required
            handleChange={handleChange}
            hasError={valid["city"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>
        <div className="flex flex-wrap mb-6">
          <SelectField
            container="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0"
            id="country"
            name="country"
            value={data.country}
            label={localLanguage.general_country}
            options={countryOptions}
            placeholder={localLanguage.general_country}
            required
            handleChange={handleChange}
            hasError={valid["country"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <SelectField
            container="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0"
            id="state"
            name="state"
            value={data.state}
            label={localLanguage.general_state_prov}
            options={subdivisionOptions}
            placeholder={localLanguage.general_state_prov}
            required
            handleChange={handleChange}
            hasError={valid["state"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
          <TextField
            container="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0"
            id="zip_code"
            name="zip_code"
            value={data.zip_code}
            label={localLanguage.general_zip_postal}
            placeholder={localLanguage.general_zip_postal}
            required
            handleChange={handleChange}
            hasError={valid["zip_code"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>
        {!freeTicket && +giftingOption > 0 && (
          <div className="flex flex-wrap mb-6">
            <label>
              <input className="ml-3 leading-tight" type="checkbox" checked={data.showRecipientForm} onChange={handleGiftTicket} />
              <span className="px-3 mb-6 md:mb-0 recipientCheckboxLabel">
                {localLanguage.general_gift_ticket}
              </span>
            </label>
            {+data.showRecipientForm > 0 &&
              <RecipientForm
                name={"recipient"}
                handleChange={handleChange}
                handleAddRecipient={this.handleAddRecipient}
                handleRemoveRecipient={this.handleRemoveRecipient}
                handleRecipientList={this.handleRecipientList}
                data={data}
                valid={valid}
                recipientLimit={recipientLimit}
                localLanguage={localLanguage}
              />
            }

          </div>
        )}
        {data.subscribe.length > 0 && <div className="flex flex-wrap">
          <CheckboxField
            container="w-full px-3"
            name="subscribe"
            label={localLanguage.general_receive_comm_via_np.replace('{context.event.nonprofit.name}', context.event.nonprofit.name)}
            options={data.subscribe}
            handleChange={this.handleSubscribeChange}
            hasError={valid["subscribe"]}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>}

        <div className="flex flex-wrap">
          <CheckboxField
            container="w-full px-3"
            name="validate"
            label={false}
            required
            options={data.agreement}
            handleChange={handleAgreement}
            hasError={agreementErrors}
            displayError={localLanguage.general_field_is_required}
            language={localLanguage.setLocale}
          />
        </div>

        {ageVerificationRequiredSms && showDateOfBirth && <div className="flex flex-wrap w-full px-3 mt-3">
          <label className="w-full mb-1">
            <strong>{localLanguage.enter_dob_text}<span className="requireClass">*</span></strong>
          </label>
          <input
            className="w-full px-3 py-2 leading-tight form-control input-lg"
            id="dateOfBirth"
            name="dateOfBirth"
            placeholder="MM/DD/YYYY"
            value={dateOfBirth}
            onChange={this.handleDateOfBirthChange}
            maxLength="10"
            hasError={valid["dateOfBirth"]}
            required
          />
          {dateOfBirthError && dateOfBirth != "" && (
            <div className="text-danger text-xs italic pr-3 mb-6 mt-3 md:mb-0">
              {dateOfBirthError}
            </div>
          )}
           {!dateOfBirth && valid['dateOfBirth'] && (
            <div className="text-danger text-xs italic pr-3 mb-6 mt-3 md:mb-0">
              {localLanguage.general_field_is_required}
            </div>
          )}
        </div>}

        <div className="w-full flex flex-wrap mt-6 mb-6 justify-between">
          <Button type="button" onClick={goToPrevious} color="secondary" textColor="primary" content={localLanguage.general_back} />
          {validEmail === true && validRecipients === true &&
            <Button
              type="button"
              color="accent"
              textColor="primary"
              onClick={validatePersonal}
              content={localLanguage.general_continue}
              continueButton="true"
            />
          }
        </div>
      </form>
    );
  }
}
CheckoutPersonalInfo.contextType = AppContext;

export default CheckoutPersonalInfo;
