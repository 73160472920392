import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Validator from "validatorjs";
import { request as xhr, queryString as qs } from "../utils/request";
import { AppContext } from "../utils/context";
import { appEnv } from "../utils/constants";
import CheckoutSteps from "../components/CheckoutSteps";
import CheckoutTickets from "../components/CheckoutTickets";
import CheckoutSummary from "../components/CheckoutSummary";
import CheckoutPersonalInfo from "../components/CheckoutPersonalInfo";
import CheckoutVerify from "../components/CheckoutVerify";
import CheckoutThankYou from "../components/CheckoutThankYou";
import CheckoutEnvelope from "../components/CheckoutEnvelope";
import classnames from 'classnames';

class CheckoutFreeTicket extends Component {
  constructor(props, context) {
    super(props);

    const { localLanguage } = context;

    let subscribeOptions = [];
    if (+context.settings.checkout_subscribe_sms > 0) {
      subscribeOptions.push({
        text: localLanguage.checkout_subscribe_sms,
        value: "text",
        checked: +context.settings.checkout_subscribe_sms > 0 ? true : false,
        id: "subscribe-text"
      });
    }
    if (+context.settings.checkout_subscribe_email > 0) {
      subscribeOptions.push({
        text: localLanguage.checkout_subscribe_email,
        value: "email",
        checked: +context.settings.checkout_subscribe_email > 0 ? true : false,
        id: "subscribe-email"
      });
    }

    this.state = {
      loading: true,
      activeIndex: 1,
      activeSteps: [1],
      cartTotal: 0,
      envelope: 0,
      beneficiaryId: 0,
      event: context.event,
      eventSubscribe: false,
      envelope_error: false,
      personal_info_error: false,
      isProcessLoading: false,
      validation: new Validator({}, {}),
      error: {
        code: 0,
        message: "",
        action: "default"
      },
      payment: {
        payToken: ''
      },
      order: {
        id: 0,
        hash: "",
        orderstatus: 0
      },
      personal: {
        id: 0,
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        email_confirmation: "",
        phone: "",
        phone_home: "",
        phone_work: "",
        address: "",
        suite: "",
        city: "",
        state: "",
        country: context.settings.country || "USA",
        zip_code: "",
        latitude: "",
        longitude: "",
        age_range: "0",
        showRecipientForm: 0,
        recipientCount: 0,
        recipientList: [],
        subscribe: subscribeOptions,
        agreement: [
          {
            text: localLanguage.checkout_agreement_age,
            value: "oldenough",
            checked: 0
          },
          {
            text: localLanguage.checkout_agreement_rules,
            value: "rules",
            checked: 0
          }
        ],
        saveInfo: [
          {
            text: localLanguage.general_save_info_next_visit,
            value: "saveinfo",
            checked: true
          }
        ]
      },
      invalidEmailError: null,
      isDateOfBirthValidated: false
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    let queryString = qs.parse(this.props.location.search);

    if (history.location.pathname.indexOf("free-tickets/thank-you") > -1 && typeof (queryString.orderId) === "undefined") {
      history.push("/free-tickets/select-tickets");
    }

    this.setState(
      {
        order: {
          id: queryString.orderId ? queryString.orderId : 0,
          hash: queryString.hash ? queryString.hash : 0
        }
      },
      () => this.handleCallback()
    );
  };
  handleCallback = () => {
    const { history } = this.props;
    let queryString = qs.parse(this.props.location.search);
    if (typeof (queryString.orderId) !== "undefined" || typeof (queryString.hash) !== "undefined") { // if user provided orderId or hash in the URL
      // if user is on thank you page and provided a valid orderId and hash
      if (history.location.pathname.indexOf("/thank-you") > -1 && typeof (queryString.orderId) !== "undefined" && typeof (queryString.hash) !== "undefined") {
        this.getOrder();
      } else { // if user provided orderId or hash in the URL but not on thank-you page, then redirect to landing page
        history.push('/');
      }
    }
    else { // if not thank-you page (assuming user is on checkout page)
      this.setup();
    }
  }

  componentWillUnmount = () => {
    const { cookies } = this.props;
    cookies.remove("order");
    cookies.remove("envelope");
  };

  geofenceError = () => {
    const { localLanguage } = this.context;
    var error = { ...this.state.error };
    error.code = 2;
    error.message = localLanguage.general_geofence_outside_bounds;
    this.setState({ error });
  }

  checkMaxMind = () => {
    const script = document.createElement("script");
    script.src = "https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js";
    script.async = true;
    script.onload = () => {
      this.setState({
        loading: false
      });
      window.geoip2.city(this.mmSuccess, this.mmError);
    };
    document.body.appendChild(script);
  };

  getOrder() {
    const { localLanguage } = this.context;
    const { order } = this.state;
    if (!order) {
      let error = {
        code: 1,
        message: localLanguage.general_max_free_ticket_reached
      }
      this.setState({ error: error });
      return false;
    }
    if (+order.id > 0) {
      return xhr
        .get(`api:/web/order/${order.id}`, {})
        .catch(err => {
          var error = { ...this.state.error };
          error.code = 10;
          error.message = err.message;
          error.action = "return";
          this.setState({ error });
          return err;
        })
        .then(resp => {
          if (+resp.id > 0) {
            order['orderstatus'] = resp.orderstatus;

            // Display error if present
            if (resp.paymentdata && resp.paymentdata.error) {
              order['error'] = {
                  'title': localLanguage.general_were_sorry ?? null,
                  'message': resp?.paymentdata?.error?.message ?? null
              };
            }

            this.setState({
              loading: false,
              order: order,
              personal: resp.orderdata,
              envelope: +resp.envelope,
              cartTotal: resp.totalcost
            });
          }
          return resp;
        });
    }
  }

  ValidateLocationSingle = (geoData, position) => {
    let self = this;

    var latTolerance = 0.01;
    var longTolerance = 0.01;

    // Check if within bounds and tolerance
    let isOutsideBounds = position.coords.latitude > geoData.border.n + latTolerance ||
        position.coords.latitude < geoData.border.s - latTolerance ||
        position.coords.longitude < geoData.border.w - longTolerance ||
        position.coords.longitude > geoData.border.e + longTolerance;

    if (isOutsideBounds) {
      this.geofenceError();
    }

    //If we reached here, they are within the bounds and within tolerance.  Align them to a resolution lookup to the geo data
    var xRes = (geoData.res - 1) / (geoData.border.e - geoData.border.w)
    var yRes = (geoData.res - 1) / (geoData.border.n - geoData.border.s)
    var xLookup = Math.floor((position.coords.longitude - geoData.border.w) * xRes)
    var yLookup = Math.floor((geoData.res - 1) - ((position.coords.latitude - geoData.border.s) * yRes))  //This one gets inverted to align with bitmap data

    //Because we allow for tolerance we need to ensure the numbers properly align to index space
    if (xLookup < 0) { xLookup = 0; }
    if (xLookup >= geoData.Res) { xLookup = geoData.res-1; }
    if (yLookup < 0) { yLookup = 0; }
    if (yLookup >= geoData.Res) { yLookup = geoData.res-1; }

    //Use the lookups to test the geo data if the location is within borders or not
    //The geo data is set as an array of hex data, each element in the array is a row in the map
    //Each row is a hex string representing the bits in that map

    var geoRowData = geoData.data[yLookup];

    if(geoData.compression == "True")
      geoRowData = self.DecompressRow(geoRowData);

    //There are 4 bits per character, find the character
    var geoElementIndex = Math.floor(xLookup / 4)
    var geoElementBitMask = 1 << (xLookup - (geoElementIndex * 4))
    var geoElementCharacter = geoRowData.charAt(geoElementIndex)
    var geoHex =  parseInt(geoElementCharacter, 16);

    //Test if the bit mask is within the hex data, if so this location is on the map
    if ((geoElementBitMask & geoHex) == geoElementBitMask) {
        return true;
    }
    else {
        this.geofenceError();
    }
  }
  ValidateLocationArray = (geoData, position) => {
    let self = this;

    var latTolerance = 0.01;
    var longTolerance = 0.01;
    var geoDataLocal = geoData;
    var found = 0;

    for(var i=0; i < geoDataLocal.length && found == 0; i++) {

       if (position.coords.latitude < geoDataLocal[i].border.n + latTolerance &&
           position.coords.latitude > geoDataLocal[i].border.s - latTolerance &&
           position.coords.longitude > geoDataLocal[i].border.w - longTolerance &&
           position.coords.longitude < geoDataLocal[i].border.e + longTolerance) {
          found = 1;
      }

      //this only means that the position was found within the bounding box for the province,
      // there can be overlap between the province's bounding boxes so need to check further
      if(found == 1) {

        var foundBoundary = i ;//where we broke out

        //If we reached here, they are within a bounds and within tolerance.  Align them to a resolution lookup to the geo data
        var xRes = (geoDataLocal[foundBoundary].res - 1) / (geoDataLocal[foundBoundary].border.e - geoDataLocal[foundBoundary].border.w)
        var yRes = (geoDataLocal[foundBoundary].res - 1) / (geoDataLocal[foundBoundary].border.n - geoDataLocal[foundBoundary].border.s)
        var xLookup = Math.floor((position.coords.longitude - geoDataLocal[foundBoundary].border.w) * xRes)
        var yLookup = Math.floor((geoDataLocal[foundBoundary].res - 1) - ((position.coords.latitude - geoDataLocal[foundBoundary].border.s) * yRes))  //This one gets inverted to align with bitmap data

        //Because we allow for tolerance we need to ensure the numbers properly align to index space
        if (xLookup < 0) { xLookup = 0; }
        if (xLookup >= geoDataLocal[foundBoundary].Res) { xLookup = geoDataLocal[foundBoundary].res-1; }
        if (yLookup < 0) { yLookup = 0; }
        if (yLookup >= geoDataLocal[foundBoundary].Res) { yLookup = geoDataLocal[foundBoundary].res-1; }

        //Use the lookups to test the geo data if the location is within borders or not
        //The geo data is set as an array of hex data, each element in the array is a row in the map
        //Each row is a hex string representing the bits in that map

        var geoRowData = geoDataLocal[foundBoundary].data[yLookup];

        if(geoDataLocal[foundBoundary].compression == "True")
          geoRowData = self.DecompressRow(geoRowData);

        //There are 4 bits per character, find the character
        var geoElementIndex = Math.floor(xLookup / 4)
        var geoElementBitMask = 1 << (xLookup - (geoElementIndex * 4))
        var geoElementCharacter = geoRowData.charAt(geoElementIndex)
        var geoHex =  parseInt(geoElementCharacter, 16);

        //Test if the bit mask is within the hex data, if so this location is on the map
        if ((geoElementBitMask & geoHex) == geoElementBitMask) {
          return true;
        }
        else {
          //this was within the province's bounding box but not in the province
          found = 0;
        }
      }//if found
    }//loop

    if(found == 0) {
        this.geofenceError();
    }
  }
  DecompressRow = (rowData) => {
    //&16-080ff01&42-0 - form of rowData

    var outputRow = "";

    for(var i = 0; i< rowData.length; i++)
    {
      var myChar = rowData[i];

      if(myChar == "&")
      {
        var startPos = i+1;
        var endPos = rowData.indexOf("-", startPos);
        i = endPos +1;

        var numberOfRepeats = rowData.substring(startPos, endPos);
        var repeatingChar = rowData[i];

        //add the repeatingChar
        for(var j = 0; j< parseInt(numberOfRepeats); j++){
          outputRow += repeatingChar;
        }
      }
      else{
        outputRow += rowData[i];
      }
    }

    return outputRow;
  }

  setup = () => {
    let self = this;
    const { event } = this.state;
    const { settings } = this.context;

    // redirect '/select-tickets' to homepage if event.shopify_enabled
    if (+event.shopify_enabled === 1 && this.props.location.pathname === "/free-tickets/select-tickets") {
      window.location.href = "/";
    }

    let promise = Promise.resolve(true);
    if (
      settings.bitmask_url !== "" ||
      settings.web_geofence_state !== "" ||
      settings.web_geofence_country !== ""
    ) {
      promise = self.getPosition();
    }

    promise.then(resp => {
      let loadPersonal = { ...this.state.personal };
      if (appEnv === "development") {
        loadPersonal["first_name"] = "Matt";
        loadPersonal["last_name"] = "annis";
        loadPersonal["email"] = "mannis2025@gmail.com";
        loadPersonal["email_confirm"] = "mannis2025@gmail.com";
        loadPersonal["phone"] = "6138971491";
        loadPersonal["phone_home"] = "";
        loadPersonal["phone_work"] = "";
        loadPersonal["address"] = "250 The Esplanade";
        loadPersonal["suite"] = "206";
        loadPersonal["city"] = "Toronto";
        loadPersonal["state"] = "ON";
        loadPersonal["zip_code"] = "M5A 4J6";
      }

      const { cookies } = this.props;
      const personalInfo = cookies.get("personal");
      const envelope = cookies.get("envelope");
      if (personalInfo) {
        loadPersonal = personalInfo;
      }
      this.setState(
        {
          loading: false,
          personal: loadPersonal,
          envelope: +envelope,
          cartTotal: 0
        }
      );
    });
  };
  getPosition = async () => {
    let self = this;
    const { settings, localLanguage } = this.context;

    const geoData = await fetch(`${settings.bitmask_url}`, {}).then(response => response.text()).catch(err => {
      this.geofenceError();
    }).then(text => {
      let data = JSON.parse(text.replace("var geoData = ", '').replace(/[a-z]+:/gi, function (x) {
        return '"' + x.replace(":", "") + '":';
      }));
      return data;
    });

    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: 5000
      });
    })
      .then(position => {
        if (position === "ignore") return "ignore";
        let personal = { ...self.state.personal };
        personal["latitude"] = position.coords.latitude;
        personal["longitude"] = position.coords.longitude;
        this.setState({ personal });
        return position.coords;
      })
      .catch(err => {
        if (settings.maxmind_enabled) {
          this.checkMaxMind();
          return "ignore";
        }

        let message = "";
        switch (err.code) {
          case err.PERMISSION_DENIED:
            message = localLanguage.general_allow_browser_location;
            break;
          case err.POSITION_UNAVAILABLE:
            message = localLanguage.general_no_location_info;
            break;
          case err.TIMEOUT:
            message = localLanguage.general_location_time_out;
            break;
          default:
            message = localLanguage.general_location_unknown_error;
        }
        var error = { ...this.state.error };
        error.code = err.code;
        error.message = message;
        error.action = "default";
        this.setState({ error });
        return false;
      })
      .then(coords => {
        if (coords === false) {
          return false;
        }
        if (coords === "ignore") {
          return "ignore";
        }

        let position = {
          coords: {
            latitude: coords.latitude,
            longitude: coords.longitude
          }
        };

        // Ensure in proper state to validate location
        let dataIsInvalid = position == null || geoData == null;
        if (dataIsInvalid) {
          this.geofenceError();
        }

        // Validate location
        if(geoData.constructor === Array){
          self.ValidateLocationArray(geoData, position);
        }
        else{
          self.ValidateLocationSingle(geoData, position);
        }
      })
  };

  mmSuccess = position => {
    const { settings } = this.context;

    if ((settings.web_geofence_country != '' && settings.web_geofence_country.toLowerCase() != position.country.names.en.toLowerCase())
    || (settings.web_geofence_state != '' && settings.web_geofence_state.toLowerCase() != position.subdivisions['0'].names.en.toLowerCase())) {
      return this.mmError();
    }

    // Position verified
    return position;
  };

  mmError = error_message => {
    const { localLanguage } = this.context;
    var error = { ...this.state.error };
    error.code = "-1";
    error.message = localLanguage.general_location_unknown_error;
    error.action = "default";
    this.setState({ error });
    return false;
  };

  errorReturnHome = () => {
    const { cookies, history } = this.props;
    cookies.remove('order');
    cookies.remove("cart");
    history.push("/")
  }

  checkout = (recaptcha) => {
    let self = this;
    const { localLanguage } = this.context;
    const { cookies } = this.props;
    const { event } = this.state;

    if (event.web_sales_paused) {
      let error = {
        "code": 1,
        "message": localLanguage.general_redemption_unavailable
      }
      self.setState({ error: error });
      cookies.remove('order');
      cookies.remove("envelope");
      return false;
    }

    this.saveCart();

    this.saveOrder()
      .then(function (resp) {
        self.setState({ order: resp.data }, () => self.processOrder(recaptcha))
        cookies.set('order', resp.data);
      })
  };
  processOrder = (recaptchaKey) => {
    let self = this;
    let recaptcha = recaptchaKey;
    const { localLanguage } = this.context;
    const { cookies, history } = this.props;
    const { event } = this.state;
    let order = self.state.order;

    if (order) {
      if (order.id && +order.orderstatus === 0) {
        return xhr.post(`api:/web/free-order/${order.id}/process`, {
          id: order.id,
          recaptcha: recaptcha,
          eventId: event.id,
          hash: order.hash,
          locale: localLanguage.setLocale.substring(0,2) || 'en'
        }).then(resp => {
          if (resp.recaptchaError) {
            let error = {
              "code": 1,
              "message": localLanguage.general_recaptcha_verify_error
            }
            self.setState({ error: error });
            cookies.remove('order');
            cookies.remove("envelope");
            return false;
          } else if (resp.alreadyClaimed) {
            // check for other error
            let error = {
              "code": 1,
              "message": localLanguage.general_max_free_ticket_reached
            }
            this.setState({ error: error });
          }

          if (!resp) {
            setTimeout(() => { self.getOrder().then(resp => {
              if (resp.id && (+resp.orderstatus === 0 || +resp.orderstatus === 1 || +resp.orderstatus === 2)) {
                history.replace();
                history.replace(`/free-tickets/thank-you?orderId=${order.id}&hash=${order.hash}`);
              } else {
                let error = {
                  "code": 0,
                  "message": localLanguage.general_problem_processing
                }
                this.setState({ error: error });
              }
              cookies.remove('order');
              cookies.remove("envelope");
            })}, 5000);
            return false;
          } else {
            const { order } = this.state;
            cookies.remove('order');
            cookies.remove("envelope");
            history.replace();
            this.setState({ isProcessLoading: false }, () => history.replace(`/free-tickets/thank-you?orderId=${order.id}&hash=${order.hash}`));
          }

          return resp;
        });
      }
    }
  };

  saveCart = () => {
    const { cookies } = this.props;

    const { personal, order } = this.state;
    if (personal.saveInfo[0].checked) cookies.set("personal", personal);
    cookies.set("order", order);
    return true;
  };
  savePersonal = () => {
    this.saveCart();
    const { personal } = this.state;
    let payload = personal;

    return xhr.post("api:/web/save-personal", payload).then(resp => {
      let personal = { ...this.state.personal };
      personal["id"] = resp.customer.id;
      this.setState({ personal });
      return resp;
    });
  };
  saveOrder = () => {
    this.saveCart();
    const { event, personal, order, cartTotal, envelope, beneficiaryId } = this.state;
    const { localLanguage } = this.context;
    let payload = {
      eventId: event.id,
      personal: personal,
      order: order,
      total: cartTotal,
      payment: {},
      envelope: envelope,
      beneficiaryId: beneficiaryId,
      locale: localLanguage.setLocale.substring(0,2)
    };

    let result;
    if (order.id > 0) {
      result = xhr.put(`api:/web/order/${order.id}`, payload);
    } else {
      result = xhr.post("api:/web/free-order", payload)
    }

    return result;
  };
  handleEnvelope = e => {
    const { cookies } = this.props;
    cookies.set("envelope", e.currentTarget.getAttribute('data-index'));
    this.setState({ envelope: +e.currentTarget.getAttribute('data-index'), envelope_error: false });
  };
  handleBeneficiary = (e) => {
    const { value } = e;
    this.setState({ beneficiaryId: value });
  }
  handleChange = e => {
    let { name, value } = e.target;
    let personal = { ...this.state.personal };
    personal[name] = value;
    this.setState({ personal });
  };
  handlePhoneNumber = e => {
    let { name, value } = e.target;
    value = value.replace(/\D/g, "");
    let personal = { ...this.state.personal };
    personal[name] = value;
    this.setState({ personal });
  }
  handleSubscribe = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let subscribe = personal.subscribe;
    subscribe.forEach(sub => {
      if (sub.value === value) sub.checked = checked;
    });
    personal["subscribe"] = subscribe;
    this.setState({ personal });
  };
  handleDateOfBirthValidation = (value) => {
    this.setState({ isDateOfBirthValidated: value });
  };
  handleAgreement = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let agreement = personal.agreement;
    agreement.forEach(agree => {
      if (agree.value === value) agree.checked = checked;
    });
    personal["agreement"] = agreement;
    this.setState({ personal });
  };
  handleSaveInfo = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let saveInfo = personal.saveInfo;
    saveInfo.forEach(info => {
      if (info.value === value) info.checked = checked;
    });
    personal["saveInfo"] = saveInfo;
    this.setState({ personal });
  };
  validateCart = () => {
    this.saveCart();
    const { event } = this.state;
    const { history } = this.props;
    event.game.id === 3 ? history.push("/free-tickets/pick-envelope") : history.push("/free-tickets/personal-info");
  };
  validateCartTickets = () => {
    this.saveCart();
    const { history } = this.props;
    history.push("/free-tickets/personal-info");
  };
  validateCartFinal = () => {
    this.saveCart();
    const { localLanguage } = this.context;
    const { history } = this.props;
    const rules = {
      envelope: "min:1"
    };
    const customMessages = {
      "min.envelope": localLanguage.general_select_env_continue
    };
    let validation = new Validator(this.state, rules, customMessages);

    if (validation.passes()) {
      history.push("/free-tickets/personal-info");
    } else {
      this.setState({ validation, envelope_error: true });
    }
  };
  emailValidation = (email) => {
    const regex = /^[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/i;
    if (regex.test(email)) {
      return true;
    }
    else {
      return false;
    }
  }
  validatePersonal = () => {
    this.saveCart();
    const { history } = this.props;
    const { localLanguage, settings } = this.context;
    const { personal, isDateOfBirthValidated } = this.state;

    // Check if subscribe-text is checked
    const isSubscribeTextChecked = personal.subscribe.some(sub => sub.id === "subscribe-text" && sub.checked);
    const ageVerificationRequiredSms = +settings?.age_verification_required_sms === 1;

    const rules = {
      "first_name": "required|string",
      "last_name": "required|string",
      "email": "required|email|confirmed",
      "email_confirmation": "required|email",
      "phone": "required|digits:10",
      "address": "required|string",
      "city": "required|string",
      "country": "required|string",
      "state": "required|string",
      "zip_code": "required|string",
      "agreement.*.checked": "accepted",
      "dateOfBirth": isSubscribeTextChecked && ageVerificationRequiredSms && !isDateOfBirthValidated ? "required|date" : ""
    };
    const customMessages = {
      "accepted.agreement.0.checked": localLanguage.general_i_am_eighteen,
      "accepted.agreement.1.checked": localLanguage.general_read_agreed_rules,
      "required.dateOfBirth": localLanguage.general_field_is_required
    };
    let validation = new Validator(this.state.personal, rules, customMessages);
    if (validation.passes()) {
      if (this.emailValidation(this.state.personal.email)) {
        history.push("/free-tickets/confirm-order");
        this.setState({ personal_info_error: false, invalidEmailError: null });
      } else {
        this.setState({ personal_info_error: true, invalidEmailError: localLanguage.general_invalid_email });
      }
    }
    this.setState({ validation });
  };
  validateConfirm = (recaptcha) => {
    const { event } = this.state;
    this.setState({ isProcessLoading: true });
    this.saveCart();

    const rules = {};
    const rulesCta = {
      envelope: "min:1"
    };

    let validation = (event.game.id == 3) ? new Validator(this.state, rulesCta) : new Validator(this.state, rules);

    if (validation.passes()) {
      this.checkout(recaptcha);
    } else {
      this.setState({ validation, personal_info_error: true, isProcessLoading: false });
    }
  }

  goToPrevious = () => {
    const { event } = this.state;
    switch (this.props.location.pathname) {
      case "/free-tickets/thank-you":
        this.props.history.push("/free-tickets/confirm-order");
        break;
      case "/free-tickets/confirm-order":
        this.props.history.push("/free-tickets/personal-info");
        break;
      case "/free-tickets/personal-info":
        event.game.id === 3 ? this.props.history.push("/free-tickets/pick-envelope") : this.props.history.push("/free-tickets/select-tickets");
        break;
      case "/free-tickets/pick-envelope":
        this.props.history.push("/free-tickets/select-tickets");
        break;
    }
  };
  getEnvelopeHeight = (url) => {
    let image = new Image();
    image.src = url;
    let height = image.height > 640 ? 640 : image.height;
    return height;
  }

  render() {
    const {
      error,
      event,
      order,
      personal,
      validation,
      envelope,
      loading
    } = this.state;
    const { settings, localLanguage, config } = this.context;
    const { pathname: path } = this.props.location;
    let envelopeBackgroundImageHeight = this.getEnvelopeHeight(settings.envelope_background_image);

    const heroBoxClassnames = classnames('container', 'content', 'm-auto', { 'box-it': +settings.checkout_box_highlighting === 1 });

    personal.subscribe && personal.subscribe.forEach(option => {
      if (option['value'] === 'text') {
        option['text'] = localLanguage.checkout_subscribe_sms;
        option['id'] = "subscribe-text";
      }
      if (option['value'] === 'email') {
        option['text'] = localLanguage.checkout_subscribe_email;
        option['id'] = "subscribe-email";
      }
  });

    personal.agreement && personal.agreement.forEach(option => {
        if (option['value'] === 'oldenough') option['text'] = localLanguage.checkout_agreement_age;
        if (option['value'] === 'rules') option['text'] = localLanguage.checkout_agreement_rules;
    });

    let steps = [];
    this.state.event.game.id === 3 ? (config.allowFreeTicket ?
      steps = [
        {
          active: path === "/free-tickets/select-tickets" ? true : false,
          title: localLanguage.general_select_tickets
        },
        {
          active: path === "/free-tickets/pick-envelope" ? true : false,
          title: localLanguage.general_pick_envelope
        },
        {
          active: path === "/free-tickets/personal-info" ? true : false,
          title: localLanguage.general_personal_info
        },
        {
          active: path === "/free-tickets/confirm-order" ? true : false,
          title: localLanguage.general_review_order
        },
        {
          active: path === "/free-tickets/thank-you" ? true : false,
          title: localLanguage.general_print_tickets
        }
      ]
      :
      steps = [
        {
          active: path === "/select-tickets" ? true : false,
          title: localLanguage.general_select_tickets
        },
        {
          active: path === "/pick-envelope" ? true : false,
          title: localLanguage.general_pick_envelope
        },
        {
          active: path === "/personal-info" ? true : false,
          title: localLanguage.general_personal_info
        },
        {
          active: path === "/confirm-order" ? true : false,
          title: localLanguage.general_review_order
        },
        {
          active: (path === "/payment-info" || path === "/process-order") ? true : false,
          title: localLanguage.general_payment_info
        },
        {
          active: path === "/thank-you" ? true : false,
          title: localLanguage.general_print_tickets
        }
      ])
      :
      steps = [
        {
          active: path === "/select-tickets" ? true : false,
          title: localLanguage.general_select_tickets
        },
        {
          active: path === "/personal-info" ? true : false,
          title: localLanguage.general_personal_info
        },
        {
          active: path === "/confirm-order" ? true : false,
          title: localLanguage.general_review_order
        },
        {
          active: (path === "/payment-info" || path === "/process-order") ? true : false,
          title: localLanguage.general_payment_info
        },
        {
          active: path === "/thank-you" ? true : false,
          title: localLanguage.general_print_tickets
        }
      ];
    if (this.state.loading === true) return '';
    return (
      <div className=" mx-auto py-6 video-main video-bg" style={{ backgroundColor: settings.hero_background_color }}>
        <div className="video cover align-center align-middle">
          {settings.checkout_background_image && settings.checkout_background_image !== "" && (
            <img
              src={`${settings.checkout_background_image}`}
              alt=""
              className="opacity-75"
            />)
          }
        </div>
        <div className={heroBoxClassnames}>
          <h1 className="mb-6">{localLanguage.general_checkout}</h1>
          <div>
              <CheckoutSteps steps={steps} />
              <CheckoutSummary
                freeTicket={config.allowFreeTicket}
                event={this.context.event}
                envelope={envelope}
                location={this.props.location}
                cookies={this.props.cookies}
              />
              <Switch>
                <Route
                  path="/free-tickets/select-tickets"
                  exact
                  render={props => (
                    <CheckoutTickets
                      freeTicket={config.allowFreeTicket}
                      context={this.context}
                      handleBeneficiary={this.handleBeneficiary}
                      validateCart={this.validateCart}
                      validateCartTickets={this.validateCartTickets}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/free-tickets/pick-envelope"
                  exact
                  render={props => (
                    <CheckoutEnvelope
                      envelope={envelope}
                      envelopes={event.cards}
                      context={this.context}
                      envelope_error={this.state.envelope_error}
                      valid={validation.errorCount > 0 ? validation.errors.errors : {}}
                      scripts_pick_envelope={settings.scripts_pick_envelope}
                      envelope_number_color={settings.envelope_number_color}
                      envelope_background_image={settings.envelope_background_image}
                      envelopeBackgroundImageHeight={envelopeBackgroundImageHeight < +settings.envelope_background_default_height ? +settings.envelope_background_default_height : envelopeBackgroundImageHeight}
                      handleEnvelope={this.handleEnvelope}
                      validateCartFinal={this.validateCartFinal}
                      goToPrevious={this.goToPrevious}
                    />
                  )}
                />
                <Route
                  path="/free-tickets/personal-info"
                  exact
                  render={props => (
                    <CheckoutPersonalInfo
                      freeTicket={config.allowFreeTicket}
                      data={personal}
                      valid={validation.errorCount > 0 ? validation.errors.errors : {}}
                      context={this.context}
                      defaultCountry={this.context.settings.country}
                      invalidEmailError={this.state.invalidEmailError}
                      handleChange={this.handleChange}
                      handlePhoneNumber={this.handlePhoneNumber}
                      handleSubscribe={this.handleSubscribe}
                      handleAgreement={this.handleAgreement}
                      handleSaveInfo={this.handleSaveInfo}
                      validatePersonal={this.validatePersonal}
                      goToPrevious={this.goToPrevious}
                      onDateOfBirthValidated={this.handleDateOfBirthValidation}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/free-tickets/confirm-order"
                  exact
                  render={props => <CheckoutVerify
                    data={personal}
                    freeTicket={config.allowFreeTicket}
                    recaptchaKey={this.context.config.google_recaptcha_public_key}
                    recaptchaType={this.context.config.option_recaptcha_type}
                    isProcessLoading={this.state.isProcessLoading}
                    context={this.context}
                    personal_info_error={this.state.personal_info_error}
                    validateConfirm={this.validateConfirm}
                    goToPrevious={this.goToPrevious}
                    {...props}
                  />}
                />
                <Route
                  path="/free-tickets/thank-you"
                  exact
                  render={props => (
                    <CheckoutThankYou
                      order={order}
                      event={event}
                      personal={personal}
                      {...props}
                      settings={this.context.settings}
                      loading={loading}
                      error={error}
                      errorReturnHome={this.errorReturnHome}
                    />
                  )}
                />
              </Switch>
            </div>
        </div>
      </div>
    );
  }
}
CheckoutFreeTicket.contextType = AppContext;

export default CheckoutFreeTicket;
