import React, { Component } from "react";
import { AppContext } from "../utils/context";
import { request as xhr, queryString } from "../utils/request";
import { blankImage, formatCurrency } from "../utils/constants";
import Alert from "../components/Alert";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Button from "../components/Button";


class PrintSuper extends Component {

    render() {
        const { localLanguage } = this.context;

        return (
            <div className={`container mx-auto py-6`}>
                <div className="max-w-xs rounded overflow-hidden mb-6 m-auto text-center">
                    <ReactToPrint content={() => this.componentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button onClick={handlePrint} content={localLanguage.general_print_ticket} />
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                <Print {...this.props} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}
PrintSuper.contextType = AppContext;

class Print extends Component {
    state = {
        data: false,
        ticketInfo: false
    }

    componentDidMount = async () => {
        let qs = queryString.parse(this.props.location.search);
        let orderId = qs.orderId ? qs.orderId : qs.orderid;
        let hash = qs.hash ? qs.hash : (qs.orderhash ? qs.orderhash : '');
        xhr.get(`api:/web/order/${orderId}/tickets`, { 'hash': hash }).then(data => {
            this.getTicketInfo(data);
        });
    }

    getTicketInfo = (data) => {
        let ticketInfo = {};
        data.tickets.forEach(ticket => {
            ticketInfo[ticket.id] = ticket;
            ticketInfo[ticket.id]["event"] = data.events[ticket.event_id];
            ticketInfo[ticket.id]["nonProfit"] = data.nonProfits[ticket.nonprofit_id];
        });
        this.setState({ ticketInfo: ticketInfo, data });
    }

    render() {
        const { data, ticketInfo } = this.state;
        const { localLanguage, config } = this.context;

        return (
            <div className={`container mx-auto py-6`}>
                {ticketInfo && Object.values(ticketInfo).map(ticket => (
                    <div key={`ticket${ticket.id}`} className="max-w-xs rounded overflow-hidden mb-6 m-auto border border-grey-light">
                        {ticket.nonProfit.barcode_position === "top" && (
                        <div className="p-2">
                            <img className="w-full" src={`data:image/png;base64,${ticket.barcode}`} alt={localLanguage.general_barcode} />
                        </div>
                        )}
                        <div className="p-2">
                            <img
                                src={`${ticket.nonProfit.web_header_url}`}
                                onError={(e) => { e.target.src = blankImage }}
                                alt={localLanguage.general_logo}
                                className="block mx-auto"
                            />
                        </div>
                        <div className="px-6 py-4 text-xl">
                            {ticket.event !== null && (
                                <p className="mb-4 font-bold text-center">{ticket.event.title}</p>
                            )}
                        </div>

                        <div className="px-6 py-4">
                            {ticket.numbers.map(num => (
                                <div key={num} className="font-bold text-xl text-center mb-2">{num}</div>
                            ))}
                        </div>
                        {config.option_number_model === 'single_number_multi_chance' && (
                                <div className="font-bold text-lg text-center mb-1">
                                    <div className="flex-1">{`${localLanguage.general_chances_to_win.charAt(0).toUpperCase() + localLanguage.general_chances_to_win.slice(1)} ${ticket.quantity}`}</div>
                                </div>
                        )}
                        {ticket.event.draws && (
                            <div className="px-6 py-4 text-xs">
                                <div className="font-bold font-bold text-xl text-center mb-4">
                                    <u>{localLanguage.general_prizes}</u>
                                </div>
                                {ticket.event.draws.map((draw, index) => (
                                    <div className="mb-2" key={index}>
                                        <div className="flex justify-between">
                                            <b><u>{draw.title}</u></b>
                                        </div>
                                        {draw.draw_at && (
                                            <div className="flex justify-between">
                                                <div className="flex-1">{localLanguage.general_draw_date}: {draw.draw_at}</div>
                                            </div>
                                        )}
                                        {draw.sales_cutoff && (
                                            <div className="flex justify-between">
                                                <div className="flex-1">{localLanguage.general_sales_cutoff}: {draw.sales_cutoff}</div>
                                            </div>
                                        )}
                                        {draw.is_main && (
                                            <div className="flex justify-between">
                                                <div className="flex-1">{localLanguage.general_main_prize_yes}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="px-6 py-4">
                            <div className="text-grey-darkest text-xs">
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1">
                                        {ticket.event.game.id == 4 ?
                                        `${localLanguage.general_daily_draws_until}:` :
                                        `${localLanguage.general_raffle_date}:`}
                                    </div>
                                    <div className="flex-1 text-right"> {ticket.event.end_at} </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {`${localLanguage.general_purchased}:`} </div>
                                    <div className="flex-1 text-right"> {ticket.purchase_time} </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {`${localLanguage.general_device}:`} </div>
                                    <div className="flex-1 text-right"> 99 </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {`${localLanguage.general_ticket_price}:`} </div>
                                    <div className="flex-1 text-right"> {formatCurrency(ticket.amount)} </div>
                                </div>
                                {ticket.event.game.id === 3 && (
                                    <div className="flex justify-between mb-2">
                                        <div className="flex-1"> {`${localLanguage.general_envelope_num}:`} </div>
                                        <div className="flex-1 text-right"> {data.envelope} </div>
                                    </div>
                                )}
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {`${localLanguage.general_rid}:`} </div>
                                    <div className="flex-1 text-right"> {ticket.event_id} </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {`${localLanguage.general_reference_num}:`} </div>
                                    <div className="flex-1 text-right"> {ticket.reference} </div>
                                </div>
                                {data.beneficiary && (
                                <div className="flex justify-between mb-2">
                                    <div className="flex-1"> {localLanguage.beneficiary_ticket_label} </div>
                                    <div className="flex-1 text-right"> {data.beneficiary.name} </div>
                                </div>
                                )}
                            </div>
                        </div>
                        {ticket.nonProfit.barcode_position === "middle" && (
                            <div className="p-2">
                                <img className="w-full" src={`data:image/png;base64,${ticket.barcode}`} alt={localLanguage.general_barcode} />
                            </div>
                        )}
                        <div className="p-2">
                            <img
                                src={`${ticket.nonProfit.web_middle_url}`}
                                onError={(e) => { e.target.src = blankImage }}
                                alt={localLanguage.general_logo}
                                className="block mx-auto"
                            />
                        </div>
                        <div className="px-6 py-4 text-xs">
                            {ticket.nonProfit.web_ticket_text_header !== null && (
                                <p className="mb-4 font-bold text-center">{ticket.nonProfit.web_ticket_text_header}</p>
                            )}
                            {ticket.nonProfit.web_ticket_text !== null && (
                                <p className="mb-4">
                                {ticket.nonProfit.web_ticket_text.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br /></span>
                                })}
                                </p>
                            )}
                            {ticket.nonProfit.web_ticket_text_footer !== null && (
                                <p className="mb-4 font-bold text-center">{ticket.nonProfit.web_ticket_text_footer}</p>
                            )}
                        </div>
                        <div className="p-2">
                            <img
                                src={`${ticket.nonProfit.web_middle_e_url}`}
                                onError={(e) => { e.target.src = blankImage }}
                                alt={localLanguage.general_logo}
                                className="block mx-auto"
                            />
                        </div>
                        <div className="p-2">
                            <img
                                src={`${ticket.nonProfit.web_footer_url}`}
                                onError={(e) => { e.target.src = blankImage }}
                                alt={localLanguage.general_logo}
                                className="block mx-auto"
                            />
                        </div>

                        {ticket.nonProfit.barcode_position === "bottom" && (
                            <div className="p-2">
                                <img className="w-full" src={`data:image/png;base64,${ticket.barcode}`} alt={localLanguage.general_barcode} />
                            </div>
                        )}

                        {ticket.discount && (
                            <div style={{border: '1px dotted', margin: '1em'}} className="px-6 py-4 text-center">
                                <img
                                    src={`${ticket.nonProfit.web_ticket_discount_code_url}`}
                                    onError={(e) => { e.target.src = blankImage }}
                                    alt={localLanguage.general_logo}
                                    className="block mx-auto"
                                />
                                <div className="flex-1 mt-2 mb-2">{`${ticket.nonProfit.web_ticket_discount_code_text}`}</div>
                                <div className="flex-1 mb-2"><b>{ticket.discount.code}</b></div>
                                <div className="flex-1"><i>{ticket.discount.description}</i></div>
                            </div>
                        )}

                    </div>
                ))}
                {(data.message && !ticketInfo) && (
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full md:w-1/2">
                            <Alert
                                visible={false}
                                error={data.message}
                                success={false}
                                message={data.message}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
Print.contextType = AppContext;

export default PrintSuper;
